<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="modalTitle"
    :modal-append-to-body="false"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'100px'">
        <el-form-item label="字典类型" prop="dataType">
          <el-select v-model="formModel.dataType">
            <el-option label="字典目录" value="1"></el-option>
            <el-option label="数据" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属目录" prop="parentId">
          <el-select
            v-model="formModel.parentId"
            filterable
            remote
            clearable
            placeholder="请输入关键词"
            :remote-method="queryMenu"
            style="width:300px"
          >
            <el-option
              v-for="dataDictionary in dataDictionaryList"
              :key="dataDictionary.id"
              :label="dataDictionary.name + '(' + dataDictionary.sortNo + ')'"
              :value="dataDictionary.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="formModel.name" placeholder="请输入名称" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="数值" prop="value">
          <el-input v-model="formModel.value" placeholder="请输入数值" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="显示序号" prop="sortNo">
          <el-input v-model="formModel.sortNo" placeholder="请输入显示序号" style="width:300px"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import dataDictionaryApi from "@/api/sys/dataDictionary";

export default {
  props: ["dictId", "modalTitle","catalog"],
  data() {
    var validateValue = (rule, value, callback) => {
        if(this.formModel.dataType == '2' && value==='') {
          callback(new Error('类型为数据时，值不能为空!'));
        } else {
          callback();
        }
    };

    return {
      formModel: {
        id: ""
      },
      ruleValidate: {
        name: [{ required: true, message: "名称不能为空", trigger: "blur" }],
        value: [{ validator: validateValue, trigger: "blur" }],
        sortNo: [
          { required: true, message: "显示序号不能为空", trigger: "blur" }
        ],
        dataType: [
          {
            required: true,
            message: "字典类型不能为空",
            trigger: "blur"
          }
        ]
      },
      dataDictionaryList: [],
      showDialog: true,
      loading: false,
      submitting: false
    };
  },
  computed: {
    dataDictionaryListFilter() {
      var list = [];

      var map = {};

      this.dataDictionaryList.forEach((item)=>{
        map[item.id] = item;
      });

      for(var key in map){
        list.push(map[key]);
      }

      return list;
    }
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;
      this.$refs["form"].validate(valid => {
        if (valid) {
          (function() {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              return dataDictionaryApi.add(self.formModel);
            } else {
              return dataDictionaryApi.update(self.formModel);
            }
          })().then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success"
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning"
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
    queryMenu(keywords,limit) {
      var formData = new FormData();
      formData.append("keywords", keywords);
      formData.append("dataType", "1");
      formData.append("excludeId", this.formModel.id);
      formData.append("limit", limit | 10);

      return dataDictionaryApi.query(formData).then(response => {
        var jsonData = response.data;

        if (jsonData.result) {
          this.dataDictionaryList = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    }
  },
  async mounted() {
    var self = this;
    self.loading = true;
    
    await this.queryMenu("",1000);

    (function() {
      if (self.dictId.length == 0) {
        return dataDictionaryApi.create();
      } else {
        return dataDictionaryApi.edit(self.dictId);
      }
    })()
      .then(response => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;

          // 增加初始值
          if(jsonData.data.parentId!=null){
            self.formModel.parentId = jsonData.data.parentId;
            // self.dataDictionaryList.push({
            //   id: jsonData.data.parentId,
            //   name: jsonData.data.parentName
            // });
          }
          else if(this.catalog!=null && this.catalog.id!=null){
            console.log(this.catalog);
            self.formModel.parentId = this.catalog.id; 
            self.formModel.dataType = "2";

            // self.dataDictionaryList.push({
            //   id: this.catalog.id,
            //   name: this.catalog.name
            // });
          }
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch(error => {
        self.$message.error(error + "");
      });
  }
};
</script>